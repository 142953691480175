<main>
  <div [ngClass]="searched ? 'main-container-searched' : 'main-container'">
    <div class="loader-container" *ngIf="loading">
      <img class="loader" src="./assets/img/psaLoading.svg" alt="Carregando..." />
    </div>
    <div [ngClass]="searched ? 'search-container-searched' : 'search-container'">
      <div [ngClass]="searched ? 'search-wrapper-searched' : 'search-wrapper'">
        <p *ngIf="!searched">Os principais nomes do mercado em um único lugar</p>
        <div [ngClass]="searched ? 'search-box-searched': 'search-box'">
          <div class="search-text">
            <label for="search">Palestrante</label>
            <input type="text" id="search" placeholder="Pesquise por palestrante, temas ou palavras chave..." [(ngModel)]="searchInput" (ngModelChange)="newSearch = true" (keyup.enter)="searchSpeakers()">
          </div>
          <button class="search-button" (click)="searchSpeakers()">
            <img ngSrc="assets/icons/lupa.svg" height="20" [width]="20" alt="Lupa">
          </button>
        </div>
        <!-- <div class="">
          Termos mais buscados
        </div> -->
      </div>
      <caption class="caption__container" *ngIf="searched && !newSearch">Você buscou por "{{ searchInput }}"</caption>
    </div>
    <div *ngIf="searched" class="speakers__container">
      <psa-speakers-list *ngIf="!loading" [speakers]="speakers" [searchInput]="searchInput"></psa-speakers-list>
    </div>  
    <div class="about-psa" *ngIf="searched">
      <div class="left-labels">
        <div><h2>Aprender é o maior show da terra</h2></div>
        <div><p>Transformamos ideias em espetáculos vivos. Histórias que iluminam mentes, conectam alma e movem o mundo.</p></div>
        <div><button class="about-button" (click)="openVideoModal()">Sobre a PSA</button></div>
      </div>
    </div>
  </div>  
</main>
