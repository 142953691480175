import {  Meta } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { HeaderComponent } from './shared/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfissionaisPerfilComponent } from './site/profissionais-perfil/profissionais-perfil.component';
import { DepoimentosComponent } from './shared/depoimentos/depoimentos.component';
import { LOCALE_ID } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FooterWhiteComponent } from './shared/footer-white/footer-white.component';
import { NgOptimizedImage } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RelacionadoCarouselComponent } from './shared/relacionados-carousel/relacionados-carousel.component';
import { FACEBOOK_PIXEL_PROVIDER } from './services/pixelService/facebook-pixel.provider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormHireSpeakerTopComponent } from './site/form-hire-speaker-top/form-hire-speaker-top.component';
import { FormSpeakerListComponent } from './site/form-speaker-list/form-speaker-list.component';
import { AskedQuestionsComponent } from './shared/asked-questions/asked-questions.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HomeComponent } from './site/home/home.component';
import { SpeakersListComponent } from './shared/speakers-list/speakers-list.component';
import { PalestrasItemComponent } from './site/palestras-item/palestras-item.component';
import { PalestrasCarouselComponent } from './site/palestras-carousel/palestras-carousel.component';
import { VideoModalComponent } from './shared/video-modal/video-modal.component';
import { FormWhatsappComponent } from './site/form-whatsapp/form-whatsapp.component';
import { FormHomeComponent } from './site/form-home/form-home.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProfissionaisPerfilComponent,
    DepoimentosComponent,
    FooterWhiteComponent,
    HeaderComponent,
    DepoimentosComponent,
    RelacionadoCarouselComponent,
    FormHireSpeakerTopComponent,
    FormWhatsappComponent,
    FormSpeakerListComponent,
    FormHomeComponent,
    AskedQuestionsComponent,
    HomeComponent,
    SpeakersListComponent,
    PalestrasItemComponent,
    PalestrasCarouselComponent,
    VideoModalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CarouselModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    NgOptimizedImage,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    CollapseModule.forRoot(),
  ],
  providers: [
    FACEBOOK_PIXEL_PROVIDER,
    Meta,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
