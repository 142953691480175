import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
})
export class VideoModalComponent implements OnInit {

  videoId: string;
  linkVideo: string;

  constructor(
    public dialogRef: MatDialogRef<VideoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { videoId: string, linkVideo: string }
  ) {}

  ngOnInit(): void {
    this.videoId = this.data.videoId;
    this.linkVideo = this.data.linkVideo;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
