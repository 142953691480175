import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'psa-form-whatsapp',
  templateUrl: './form-whatsapp.component.html',
  styleUrls: [
    './form-whatsapp.component.css',
  ],
})
export class FormWhatsappComponent implements OnInit {
  isHidden: boolean = true;
  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src =
      'https://psa-profissionaissa.activehosted.com/f/embed.php?id=433';
    script.charset = 'utf-8';

    this._renderer2.appendChild(this._document.body, script);
  }

  openForm(): void {
    this.isHidden = false;
  }

  closeForm(): void {
    this.isHidden = true;
  }
}
