import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Meta, Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { SpeakerService } from 'src/app/services/speaker.service';
import { SEOService } from 'src/app/services/SEOService';

export interface IArtigo {
  titulo: string,
  artigo: string,
  resumo: string,
  slug: string,
  image_src: string
  tipo?: string
  fonte?: string
  data?: string
  subtitulo?: string
  id_conteudo_tipo?: number
  categoria?: string
  categoria_slug?: string
}

export interface IFormUser {
  inputs: Array<string>
  requireinputs: Array<string>
  textButton: string

  buttonCenter?: boolean
  showModalSuccess?: boolean
  showError?: boolean
  customRDKey?: string
  customOrigemRD?: 'formulario' | 'whatsapp'
  themeKeyRD?: 'ctr_form' | 'edu_corp_form' | 'espec_form' | 'prof_form'
}

interface IPalestra {
  id: number;
  photo: string;
  nome: string;
  descricao: string;
  foto_tema: string;
}

interface IDepoimento {
  cidade: string;
  data: string;
  descricao: string;
  empresa: string;
  nome: string;
}

interface IProfissionalAssuntos {
  nome: string;
}

interface IProfissional {
  photo: string;
  slug: string;
  nome: string;
  sobrenone: string;
  titulo: string;
  minibio: string;
  formatos?: string[];
  palestrante_video: string;
  [key: string]: any;
}

interface IProfissionalRelacionado extends IProfissional {
  nome_sobrenome: string;
}

@Component({
  selector: 'psa-profissionais-perfil',
  templateUrl: './profissionais-perfil.component.html',
  styleUrls: ['./profissionais-perfil.component.css'],
})
export class ProfissionaisPerfilComponent implements OnInit {

  private _palestras: IPalestra[] = [];
  private _temas_id: string;

  formUserProfile: IFormUser = {
    requireinputs: ['assunto'],
    inputs: ['assunto'],
    textButton: 'RECEBER SOLUÇÕES INCRÍVEIS',
  };

  public isSaNewsLoading: boolean = true;
  public postsBlog: IArtigo[];

  especialistaTemas: Array<string> = [];
  depoimentos: Array<IDepoimento> = [];
  palestrasOferecidas: any;
  assuntos: IProfissionalAssuntos[];

  isLoading: boolean = false;
  profissional: IProfissional = {
    photo: '',
    slug: '',
    nome: '',
    sobrenone: '',
    titulo: '',
    minibio: '',
    palestrante_video: '',
  };
  public profissionaisRelacionados: IProfissionalRelacionado[] = [];
  public carouselOptions: OwlOptions = {
    margin: 40,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
  };

  loading: boolean = false;
  loadingSpeaker: boolean = false;
  loadingPalestras: boolean = false;
  loadingDepoimentos: boolean = false;
  loadingRelacionados: boolean = false;

  linkVideo: any = null;
  videoId: any;
  backgroundUrl: string;

  utmParams: any = {};

  constructor(
    private speakerService: SpeakerService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public sanitizer: DomSanitizer,
    private viewportScroller: ViewportScroller,
    private meta: Meta,
    private seoService: SEOService,
  ) {
    // SEO
    // this.seoService.updateCanonical(window.location.href);

    this.titleService.setTitle('Palestrante - Profissionais SA');
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentUrl = window.location.href;

        this.meta.updateTag({ property: 'og:url', content: currentUrl });
      });

    this.route.queryParamMap.subscribe(
      (result: any) => {
        if(Object.keys(result.params).length === 0) {
          this.utmParams = {
            utm_source: 'organico'
          };
        } else {
          this.utmParams = {};
        }
      }
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe(({ id: slug }) => {
      if (window.history.state.hasOwnProperty('temas_id')) {
        this._temas_id = window.history.state.temas_id;
      }
      if (slug === 'joel-jota' || slug === 'thiago-nigro') {
        window.location.href = 'https://profissionaissa.com';
      }

      if (!slug) {
        window.location.href = 'https://profissionaissa.com';
      }

      this.getPalestrante(slug, this._temas_id);
    });
  }

  public getPalestras(quantity: number = 3): IPalestra[] {
    return this._palestras.slice(0, quantity);
  }

  public async getPalestrante(slug: string, temas_id: string): Promise<any> {
    this.linkVideo = null;
    this.loading = true;
    this.loadingSpeaker = true;

    const speaker = await this.speakerService.getSpeakers(slug).catch(
      (err: any) => {
        window.location.href = 'https://profissionaissa.com';
        this.loading = false;
      }
    );

    if (!speaker?.id) {
      window.location.href = 'https://profissionaissa.com';
    }

    this.profissional = {
      ...speaker,
      nome_palestrante: `${speaker.usuario.nome} ${speaker.usuario.sobrenome}`,
    }
    this.getUrlVideo();
    this.assuntos = this.profissional?.assuntos?.slice(0,8);

    this.loading = false;
    this.loadingSpeaker = false;

    // get palestras
    this.getPalestrasSpeaker(speaker.id);
    this.getDepoimentos(speaker.id);
    this.getRelacionados(speaker.id, speaker?.assuntos);

    this.titleService.setTitle(
      `Contratar ${this.profissional.nome_palestrante}`
    );

    // atualia meta tags
    this.meta.updateTag({ name: 'Title', content: this.profissional.nome_palestrante }, "name=Title");
    this.meta.updateTag({ name: 'Description', content: this.profissional.minibio
   });
  }

  async getPalestrasSpeaker(id: string) {
    this.loadingPalestras = true;
    const palestras = await this.speakerService.getPalestras(id).catch(
      (err: any) => {
        console.error(err);
        this.loadingPalestras = false;
      }
    );

    this.palestrasOferecidas = palestras;
    if (this.palestrasOferecidas.length === 1) {
      this.carouselOptions.responsive = {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 4,
        },
        940: {
          items: 5,
        },
      };
    }
    this.loadingPalestras = false;
  }

  async getDepoimentos(id: string) {
    this.loadingDepoimentos = true;
    const speakerDepoimentos = await this.speakerService.getDepoimentos(id).catch(
      (err: any) => {
        this.loadingDepoimentos = false;
      }
    );

    this.depoimentos = speakerDepoimentos;
    this.loadingDepoimentos = false;
  }

  async getRelacionados(id: string, themes: any) {
    this.loadingRelacionados = true;

    const themesIds = themes.length > 0 ? themes.map((theme: any) => theme.id) || [] : [];

    const relacionados = await this.speakerService.getRelacionados(id, themesIds).catch(
      (err: any) => {
        console.error(err);
        this.loadingRelacionados = false;
      }
    );

    this.profissionaisRelacionados = relacionados[0];
    this.loadingRelacionados = false;
  }

  getUrlVideo() {
    if (this.profissional.video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/watch?v=${this.profissional.video}`
      );
      this.videoId = this.profissional.video.split('/').pop().trim();

      return this.linkVideo;
    } else if (this.profissional.video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://youtube.com/watch?v=${this.profissional.video}`
      );
      this.videoId = this.profissional.video.split('/').pop().trim();
    } else {
      this.linkVideo = null;
      return null;
    }
  }

  navigateProposal(proposal: any) {
    console.log(proposal);
    this.router.navigate(['palestra/', (proposal.slug)], { queryParamsHandling: 'merge', queryParams: this.utmParams});
  }

  goto() {
    this.viewportScroller.scrollToAnchor('orcamentoTop');
  }
}
