<footer>
  <div class="upper-footer">
    <div class="div-logo">
      <img ngSrc="assets/img/psa-logo.svg" height="70" [width]="120" alt="PSA logotipo">
    </div>
    <div><p class="font-label">Aprender é o maior show da terra.</p></div>
    <!-- <div></div>
    <div class="div-subList">
      <div class="sublist-title">Soluções</div>
      <a class="sublist-item">PSA Talk</a>
      <a class="sublist-item">PSA Edu</a>
      <a class="sublist-item">Imersões</a>
      <a class="sublist-item">The Speakers Brasil</a>
    </div>
    <div class="div-subList">
      <div class="sublist-title">Explore</div>
      <a class="sublist-item">Líderes de Pensamento</a>
      <a class="sublist-item">Conteúdos</a>
      <a class="sublist-item">Institucional</a>
      <a class="sublist-item">Login</a>
    </div>
    <div class="div-subList">
      <div class="sublist-title">Conteúdos</div>
      <a class="sublist-item">Free e-Books</a>
      <a class="sublist-item">Development Tutorial</a>
      <a class="sublist-item">Conteúdos</a>
      <a class="sublist-item">Playlist PSA</a>
    </div> -->
  </div>
  <div class="down-footer">
    <div class="footer-subtitle">Palestras ● Conhecimento ● Educação</div>
    <div class="separator-and-links">
      <div class="separator"></div>
      <div class="links">
        <div class="link"><a href="https://www.youtube.com/profissionaissa" target="_blank"><img ngSrc="assets/img/youtube-footer.svg" height="40" [width]="40" alt="YouTube footer"></a></div>
        <div class="link"><a href="https://br.linkedin.com/company/profissionais-sa" target="_blank"><img ngSrc="assets/img/linkedin-footer.svg" height="40" [width]="40" alt="Linkedin footer"></a></div>
        <div class="link"><a href="https://www.facebook.com/PSA.profissionaissa" target="_blank"><img ngSrc="assets/img/facebook-footer.svg" height="40" [width]="40" alt="Facebook footer"></a></div>
        <div class="link"><a href="https://www.instagram.com/profissionaissa" target="_blank"><img ngSrc="assets/img/instagram-footer.svg" height="40" [width]="40" alt="Instagram footer"></a></div>
        <div class="link-spotify"><a href="https://open.spotify.com/playlist/68K6HhseJlJAjtSriIBXzo?si=sCqLipvfQ4Czzl4Hzm2YuQ&pi=M3CwQskjT_uVx&nd=1&dlsi=3e324d20015f46fe" target="_blank"><img ngSrc="assets/img/spotify-footer.svg" height="30" [width]="30" alt="Spotify footer"></a></div>
      </div>
    </div>
    <div class="terms-and-rights">
      <div class="rights">2025 PSA.</div>
      <div class="rights">TODOS OS DIREITOS DE USO RESERVADOS.</div>
      <div class="rights">
        <a href="https://psapublicfiles.blob.core.windows.net/psapublicfilescontainer/termos-de-uso/Política%20de%20Privacidade%20e%20Termos%20de%20Uso%20de%20Dados-%20PSA.pdf" target="_blank">
          TERMOS DE USO
        </a>
      </div>
    </div>
  </div>
</footer>
