<section class="orcamento-top">
  <div class="form-orcamento">
    <div class="title-orcamento">
      <h3>CONTRATE UMA PALESTRA DE {{ speakerName }}</h3>
      <p>
        Receba um orçamento personalizado para sua empresa ou evento
      </p>
      <div class="image-container">
        <img ngSrc="assets/img/maior-show-da-terra.svg" height="180" [width]="180" alt="Maior show da Terra">
      </div>
    </div>
    <div class="formOrcamento">
      <div class="_form_437" id="form-active-campaign"></div>
    </div>
    <div class="right-comments">
      <div>
        <div class="comments-star">
          <img class="comment-star" ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img class="comment-star" ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img class="comment-star" ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img class="comment-star" ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img class="comment-star" ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
        </div>
        <div class="comment">
          <p>"Só agradecer, mesmo. Atendimento de excelência e paciência em me ajudar a solucionar os trâmites internos. PSA, vocês são ótimos nisso!"</p>
        </div>
        <div class="comment-identification-container">
          <div class="comment-title">Peugeot</div>
          <div>Felipe M.</div>
        </div>
      </div>
      <div>
        <div class="comments-star">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
        </div>
        <div class="comment">
          <p>"Fomos muito bem atendidos e acompanhados. A reunião de alinhamento com o palestrante foi ótima; foi muito atencioso e construiu uma palestra em cima do que foi proposto."</p>
        </div>
        <div class="comment-identification-container">
          <div class="comment-title">Somos Educação</div>
          <div>Victoria U.</div>
        </div>
      </div>
      <div>
        <div class="comments-star">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
          <img ngSrc="assets/icons/star-comment.svg" height="20" [width]="20" alt="Estrela">
        </div>
        <div class="comment">
          <p>"O curador foi muito atencioso em atender nossa demanda, ficamos com muita dúvida na escolha e ele trouxe várias opções dentro do alinhamento que tivemos sobre nosso perfil. Ele foi assertivo."</p>
        </div>
        <div class="comment-identification-container">
          <div class="comment-title">Sicoob Uni</div>
          <div>J. Caetano</div>
        </div>
      </div>
    </div>
  </div>
</section>
