<main>
  <div class="loader-container" *ngIf="loading">
    <img class="loader" src="./assets/img/psaLoading.svg" alt="Carregando..." />
  </div>

  <div [ngClass]="searchButtonClicked ? 'forms__container__searched' : 'forms__container'">
    <div class="text__container">
      <h1>+ DE 16MIL <span class="text__span">PALESTRANTES CADASTRADOS</span></h1>
      <h5>DOS ESPECIALISTAS DE NICHO AOS MAIS FAMOSOS DO BRASIL</h5>
    </div>
    <div class="input__container">
      <div class="search__wrapper">
        <svg class="search-icon" width="24" height="24" viewBox="0 0 24 24" fill="#221938" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.31 15.9L20.71 19.29C20.8993 19.4778 21.0058 19.7334 21.0058 20C21.0058 20.2666 20.8993 20.5222 20.71 20.71C20.5222 20.8993 20.2666 21.0058 20 21.0058C19.7334 21.0058 19.4778 20.8993 19.29 20.71L15.9 17.31C14.5025 18.407 12.7767 19.0022 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19.0022 12.7767 18.407 14.5025 17.31 15.9ZM11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5Z" fill="#575758"/>
        </svg>
        <input [(ngModel)]="searchInput" (keyup.enter)="searchSpeakers()" class="search__input" type="text" placeholder="Pesquise por tema, palestrante, etc..." />
      </div>
      <button type="button" class="search__button" (click)="searchSpeakers()"><strong>BUSCAR</strong></button>
    </div>
    <caption class="caption__container" *ngIf="searchButtonClicked">Resultados para busca "{{ searchInput }}"</caption>
  </div>
  <div *ngIf="searchButtonClicked" class="speakers__container">
    <psa-speakers-list *ngIf="!loading" [speakers]="speakers"></psa-speakers-list>
  </div>
</main>
