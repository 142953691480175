<!-- <psa-orcar-buttom></psa-orcar-buttom> -->

<main class="profissional">
  <section class="profissional__header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12 align-items-center test-border mt-5">
          <div class="profissional__header__hero" *ngIf="loading">
            <div class="profissional__header__hero__img m-auto" style="width: 270px; height: 270px;"></div>
          </div>
          <div class="profissional__header__hero" *ngIf="!loading">
            <img class="profissional__header__hero__img m-auto" loading="lazy" priority="true" width="270" height="270" ngSrc="{{ profissional.photo }}"
              [attr.alt]="'Profissional ' + profissional.slug" />
          </div>
        </div>
        <div class="col-md-12 m-auto text-center test-border">
          <div *ngIf="loading" class="profissional__header__info" style="height: 400px;">
          </div>
          <div *ngIf="!loading" class="profissional__header__info">
            <h1 class="profissional__header__info__title">
              {{ profissional.nome_palestrante }}
            </h1>
            <p class="profissional__header__info__subtitle">
              {{ profissional.titulo }}
            </p>
            <div class="profissional__header__info__bio profissional__bio mt-5" [innerHTML]="profissional.minibio"></div>
          </div>

          <div>
            <a [routerLink]="[]" fragment="speakerForm" class="profile__button">Quero receber um orcamento</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <psa-form-hire-speaker-top class="mt-5" id="speakerForm" [speakerName]="profissional.nome_palestrante"></psa-form-hire-speaker-top>

  <section class="profissional__temas" *ngIf="assuntos">
    <div class="profissional__temas__wrapper">
      <h2 class="profissional__temas__title">
        Temas Abordados por {{ profissional.nome_palestrante }}
      </h2>
      <div class="profissional__temas__pills">
        <span class="profissional__temas__pills__item" *ngFor="let assunto of assuntos">{{ assunto.nome }}</span>
      </div>
    </div>
  </section>

  <section class="profissional__reasons mt-4" *ngIf="profissional.reasons">
    <h2 class="profissional__temas__title mt-3">Por que contratar {{profissional.nome_palestrante}}</h2>
    <div class="profissional__reasons__content mt-5" [innerHTML]="profissional.reasons"></div>
    <div class="profissional__palestras__footer profissional__palestras__button">
      <a [routerLink]="[]" fragment="speakerForm" class="profile__button">
        Solicitar orçamento
      </a>
    </div>
  </section>

  <section class="profissional__palestras" *ngIf="palestrasOferecidas?.length > 0">
    <div class="profissional__palestras__title">
      <p>Palestras de</p>
      <h2>{{profissional.nome_palestrante}}</h2>
    </div>

    <div class="profissional__palestras__container">
      <div
        *ngFor="let palestra of palestrasOferecidas"
        class="profissional__palestras__items"
      >
        <div>
          <h2>{{ palestra.nome }}</h2>
          <p>{{ palestra.descricao }}</p>
        </div>
      </div>
    </div>

    <div class="profissional__palestras__footer">
      <a [routerLink]="[]" fragment="speakerForm" class="profile__button">
        Solicitar orçamento
      </a>
    </div>
  </section>

  <section class="profissional__depoimentos" *ngIf="depoimentos?.length === 1">
    <div class="container depoimentos__wrapper">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div *ngIf="depoimentos.length > 0">
            <psa-depoimentos [palestrante]="profissional.nome_palestrante"
              [depoimentos]="depoimentos"></psa-depoimentos>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </section>

  <section class="profissional__depoimentos" *ngIf="depoimentos?.length > 1">
    <div class="container depoimentos__wrapper">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col">
          <div *ngIf="depoimentos.length > 0">
            <psa-depoimentos [palestrante]="profissional.nome_palestrante"
              [depoimentos]="depoimentos"></psa-depoimentos>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
    </div>
  </section>

  <psa-asked-questions *ngIf="profissional.id" [palestrante]="profissional"></psa-asked-questions>
</main>

<psa-relacionados-carousel [relacionados]="profissionaisRelacionados" *ngIf="!loading"></psa-relacionados-carousel>
