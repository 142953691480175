import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfissionaisPerfilComponent } from './site/profissionais-perfil/profissionais-perfil.component';
import { HomeComponent } from './site/home/home.component';
import { PalestrasItemComponent } from './site/palestras-item/palestras-item.component';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  template: ''
})
export class RedirectComponent {
  constructor(private router: Router) {
    this.router.navigate(['/palestrantes'], { replaceUrl: true });
  }
}

const routes: Routes = [
  {path: 'palestrantes', component: HomeComponent },
  {path: 'palestrante/:id', component: ProfissionaisPerfilComponent},
  {path: 'palestra/:slug', component: PalestrasItemComponent},
  {path: 'palestrante/:palestrante/palestra/:slug', component: PalestrasItemComponent},
  {path: '**', component: RedirectComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

