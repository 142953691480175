import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpeakerService } from 'src/app/services/speaker.service';
import { MatDialog } from '@angular/material/dialog';
import { FormSpeakerListComponent } from '../form-speaker-list/form-speaker-list.component';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'psa-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  searchQuery: string = '';
  searchInput: string = '';
  speakers: any = [];
  loading: boolean = false;
  formFilled: boolean = false;
  searchButtonClicked: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private palestrantesService: SpeakerService,
    public dialog: MatDialog,
    private meta: Meta,
  ) {
    router.queryParamMap.subscribe(
      (result: any) => {
        this.searchQuery = result.params.search;
      }
    )
  }

  ngOnInit(): void {
    const cookies = document.cookie.split('; ');

    for (let cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'formFilled') {
        this.formFilled = Boolean(value) || false;
      }
    }

    this.loading = true;
    this.meta.updateTag({ name: 'Description', content: "Encontre o palestrante ideal na Profissionais SA | PSA! São +16 mil especialistas em diversos temas para inspirar, engajar e transformar seu evento."});
    this.getSpeakers();
    this.loading = false;
  }

  openFormModal(): void {
    const dialogRef = this.dialog.open(FormSpeakerListComponent, {
      backdropClass: "dialog__container",
      width: '375px',
      height: '750px'
    });

    dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.searchButtonClicked = false;
        this.formFilled = false;
      }
  });

    dialogRef.backdropClick().subscribe(() => {
      this.searchButtonClicked = false;
      this.formFilled = false;
    });

    dialogRef.componentInstance.formSubmitted.subscribe(() => {
      this.formFilled = true;
      document.cookie = `formFilled=true; path=/; max-age=${60 * 60 * 24}`;
    });
    
  }

  getSpeakers() {
    this.loading = true;
    this.savePesquisa(this.searchQuery);
    this.palestrantesService.searchSpeakers(this.searchQuery).subscribe(
      (result: any) => {
        this.speakers = result.body;
        this.loading = false;
      }
    );
  }

  searchSpeakers() {
    this.loading = true;
    this.savePesquisa(this.searchInput);
    this.palestrantesService.searchSpeakers(this.searchInput).subscribe(
      (result: any) => {
        this.speakers = result.body;
        this.loading = false;
        this.searchButtonClicked = true;
        if(!this.formFilled && this.speakers.length > 0)
          this.openFormModal();
      }
    );
  }

  savePesquisa(pesquisa: string) {
    this.palestrantesService.savePesquisa(pesquisa).subscribe(
      (result: any) => {
      }
    );
  }
}
