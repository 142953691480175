<section class="profissional-relacionados" *ngIf="relacionados?.length > 0">
  <div class="profissional-relacionados-title">
    <h2>
      + de 20mil palestrantes
    </h2>
    <p>Palestras são o epicentro da mudança. Temos a voz para transformar sua empresa ou evento</p>
    <div><button class="talkers-button">Ver Mais</button></div>
  </div>
  <owl-carousel-o [options]="carouselOptions" class="profissional-relacionados-carrosel">
    <ng-container *ngFor="let profissional of relacionados">
      <ng-template carouselSlide>
        <div>
          <article class="profissional__relacionados__slide d-flex flex-column text-left">
            <div class="profissional__relacionados__slide__hero">
              <img
                class="profissional__relacionado__image"
                fill
                ngSrc="{{ profissional.photo }}"
                alt="{{ 'Palestrante' + profissional.slug }}"
              />
            </div>
            <h3 class="profissional__relacionados__slide__title">
              {{ profissional.nome_sobrenome }}
            </h3>
            <p class="profissional__relacionados__slide__text">
              {{ getMinibio(profissional?.titulo) }}
            </p>
          </article>
          <button class="custom__button" (click)="navigateToRelacionadoProfile(profissional.slug, profissional.id_tema)">Ver Perfil→</button>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>
