<div class="main__container">
  <div *ngFor="let profissional of speakers">
    <article
      class="profissional__relacionados__slide d-flex flex-column text-left"
    >
      <div class="profissional__relacionados__slide__hero">
        <img
          class="profissional__relacionado__image"
          fill
          ngSrc="{{ profissional.image }}"
          alt="{{ 'Palestrante' + profissional.identifier }}"
        />
      </div>
      <h3 class="profissional__relacionados__slide__title">
        {{ profissional.nomeCompleto }}
      </h3>
      <p class="profissional__relacionados__slide__text">
        {{ getMinibio(profissional?.title) }}
      </p>
      <button class="talkers-button" (click)="navigateToRelacionadoProfile(profissional.identifier, profissional.id_tema)">Ver Perfil→</button>
    </article>
  </div>
  <div class="container">
    <div class="not__found" *ngIf="speakers.length == 0">
      <h3>Nenhum resultado encontrado para "{{ fixedSearchInput }}".</h3>
      <p>Não encontrou o que estava buscando? <br> Entre em contato com um dos nossos curadores:</p>
      <div class="ml-2">
        <img src="assets/icons/wpp.svg" width="16" height="16" alt="whatsapp" />
        <span class="ml-1">
          <a target="_blank" href="https://api.whatsapp.com/send?phone=5511942770298&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es">(11) 94277-0298</a>
        </span>
      </div>
    </div>
  </div>
  
</div>
