<footer class="footer-white">
  <div class="content_contaiener row">
    <section class="col">
      <img ngSrc="assets/img/Logo-negativa.svg" width="100" height="46,38" alt="logo-psa" />

      <div class="mt-2 ml-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F54017" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
        </svg>
        <span class="ml-1">Av. Ipiranga, 6681 Sl 601 prédio 99A - Partenon, POA/RS, 90619-900</span>
      </div>

      <!-- <div class="ml-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F54017" class="bi bi-envelope-fill" viewBox="0 0 16 16">
          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
        </svg>
        <span class="ml-1">contato@profissionaissa.com</span>
      </div> -->

      <div class="ml-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F54017" class="bi bi-telephone-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
        </svg>
        <span class="ml-1">(11) 96403-9325</span>
      </div>
    </section>

    <!-- <section class="col">
      <div class="text-left list_container">
        <p class="footer_titles ml-4">Nossas Páginas</p>
        <ul class="m-0">
          <li><a [href]="externalLinks.home">Home</a></li>
          <li><a [href]="externalLinks.talks">Palestras e treinamentos</a></li>
          <li><a [href]="externalLinks.policy">Política de Privacidade</a></li>
          <li> <a [href]="externalLinks.terms">Termos de Uso e condições gerais</a></li>
        </ul>
      </div>
    </section>

    <section class="col">
      <p class="footer_titles ml-4">Principais temas de palestras</p>
      <ul>
        <li><a [href]="externalLinks.diversity">Diversidade e Inclusão</a></li>
        <li> <a [href]="externalLinks.entrepreneurship">Empreendedorismo</a></li>
        <li><a [href]="externalLinks.leadership">Liderança</a></li>
        <li><a [href]="externalLinks.motivation">Motivação</a></li>
        <li><a [href]="externalLinks.health">Saúde</a></li>
        <li><a [href]="externalLinks.sustainability">Sustentabilidade</a></li>
        <li><a [href]="externalLinks.sales">Vendas</a></li>
      </ul>
    </section> -->
  </div>
</footer>
