import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: 'psa-relacionados-carousel',
  templateUrl: './relacionados-carousel.component.html',
  styleUrls: ['./relacionados-carousel.component.css'],
})

export class RelacionadoCarouselComponent implements OnInit {
  @Input() relacionados: any = [];

  public carouselOptions: OwlOptions = {
    margin: 40,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
        center: true,
      },
      620: {
        items: 2,
      },
      790: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1250: {
        items: 5,
      },
    },
  };

  utmParams: any = {};
  utmPlain: string = '?';

  constructor (
    private router: Router,
    private activetedRoute: ActivatedRoute,
  ) {
    this.activetedRoute.queryParamMap.subscribe(
      (result: any) => {
        for (let key in result.params) {
          this.utmPlain = this.utmPlain + key + '=' + result.params[key] + '&';
          this.utmPlain = this.utmPlain.slice(0, this.utmPlain.length - 1);
        }

        if (Object.keys(result.params).length === 0) {
          this.utmParams = {
            utm_source: 'organico',
          }
          this.utmPlain += 'utm_source=organico'
        } else {
          this.utmParams = {};
        }
      }
    )
  }

  ngOnInit() { }

  public navigateToRelacionadoProfile(slug: string, temas_id: string): void {
    const extras: NavigationExtras = {
      state: {
        temas_id,
      },
      queryParamsHandling: "merge",
      queryParams: this.utmParams,
    };

    this.router.navigate(['/palestrante/', slug], extras);
  }

  public getMinibio(text: string, maxLength: number = 100) {
    return text.length > maxLength
      ? text.substring(0, maxLength - 4) + ' ...'
      : text;
  }

  navigateWithUTM() {
    window.open("https://profissionaissa.com/palestrantes-e-especialistas/" + this.utmPlain);
  }
}
