import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private canonicalLink: HTMLLinkElement;
  constructor(private router: Router, @Inject( DOCUMENT ) private document: Document ) {
    this.canonicalLink = this.document.createElement( 'link' );
    this.canonicalLink.setAttribute( 'rel', 'canonical' );
    this.canonicalLink.setAttribute( 'href', 'https://palestrantes.profissionaissa.com.br/palestrante' );
    this.document.head.append( this.canonicalLink );

    this.router.events.pipe(
      filter( e => e instanceof NavigationEnd)
    ).subscribe( ( e: NavigationEnd ) => {
      this.canonicalLink.setAttribute( 'href', this.document.URL );
    } );
  }

  updateCanonical(url: string): void {
    const head = document.getElementsByTagName('head')[0];
    // let link: HTMLLinkElement = head.querySelector(`link[rel='canonical']`) || document.createElement('link');

    // link.setAttribute('rel', 'canonical');
    this.canonicalLink.setAttribute('href', url);
  }

  initCanonicalUpdater(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const canonicalUrl = window.location.href;
      this.updateCanonical(canonicalUrl);
    });
  }
}
