<main class="profissional">
  <div class="loader-container" *ngIf="loading">
    <img class="loader" src="./assets/img/psaLoading.svg" alt="Carregando..." />
  </div>
  <div class="background-container">
    <div class="arrow-back-container">
      <div class="arrow-back">
        <button class="arrow" (click)="goBack()">
          <img ngSrc="assets/icons/arrow.svg" height="14" [width]="15" alt="Botão para voltar">
          <span class="text-arrow">Voltar para a lista</span>
        </button>
      </div>
    </div>
    <section class="profissional-header">
      <div class="profissional-left">
        <div class="profissional__header__hero" *ngIf="!loadingSpeaker">
          <img class="profissional-header-hero-img" loading="lazy" priority="true" width="200" height="200" ngSrc="{{ profissional.photo }}"
            [attr.alt]="'Profissional ' + profissional.slug" />
        </div>
        <div class="header-title-container">
          <h1 class="profissional-header-info-title">
            {{ profissional.nome_palestrante }}
          </h1>
        </div>
        <div class="stars">
          <img ngSrc="assets/icons/star.svg" height="24" [width]="28" alt="Estrela">
          <img ngSrc="assets/icons/star.svg" height="24" [width]="28" alt="Estrela">
          <img ngSrc="assets/icons/star.svg" height="24" [width]="28" alt="Estrela">
          <img ngSrc="assets/icons/star.svg" height="24" [width]="28" alt="Estrela">
          <img ngSrc="assets/icons/star.svg" height="24" [width]="28" alt="Estrela">
        </div>
      </div>
      <div>
        <div *ngIf="!loadingSpeaker" class="profissional-header-info">
          <p class="profissional-header-info-subtitle">
            {{ profissional.titulo }}
          </p>
          <div class="profissional-header-info-bio" [innerHTML]="profissional.minibio"></div>
        </div>
      </div>
    </section>
  </div>

  <psa-form-hire-speaker-top id="speakerForm" [speakerName]="profissional.nome_palestrante" style="width: 100%;"></psa-form-hire-speaker-top>

  <section class="profissional-video" *ngIf="videoId">
    <div class="div-title">
      <h2 class="profissional-temas-title">
        Saiba mais sobre {{ profissional.nome_palestrante }}
      </h2>
    </div>
    <div class="video-container">
      <lite-youtube [attr.videoid]="videoId">
        <a class="lite-youtube-fallback" [href]="linkVideo">Watch on YouTube: "Sample output of devtools-to-video cli tool"</a>
      </lite-youtube>
    </div>
  </section>
  
  <section class="profissional-reasons" *ngIf="profissional.reasons">
    <div class="div-title">
      <h2 class="profissional-temas-title">Por que contratar {{profissional.nome_palestrante}}?</h2>
    </div>
    <div class="profissional-reasons-content" [innerHTML]="profissional.reasons"></div>
  </section>
  
  <section class="profissional-temas" *ngIf="assuntos">
    <div class="profissional-temas-wrapper">
      <h2 class="profissional-temas-title">
        Temas Abordados
      </h2>
      <div class="profissional-temas-pills">
        <span class="profissional-temas-pills-item" *ngFor="let assunto of assuntos">{{ assunto.nome }}</span>
      </div>
    </div>
  </section>

  <section class="profissional-palestras" *ngIf="palestrasOferecidas?.length > 0 && !loadingPalestras">
    <div class="profissional-palestras-title">
      <h4>{{profissional.nome_palestrante}}</h4>
      <h2>Palestra(s)</h2>
      <p>É com histórias inspiradoras e conhecimento profundo que apresentamos novas formas de pensar.</p>
      <div class="profissional-palestras-title-icons">
        <img ngSrc="assets/icons/talk-icon-1.svg" height="40" [width]="40" alt="PSA">
        <img ngSrc="assets/icons/talk-icon-2.svg" height="40" [width]="40" alt="PSA">
        <img ngSrc="assets/icons/talk-icon-3.svg" height="40" [width]="40" alt="PSA">
      </div>
      <div class="profissional-palestras-button">
        <a [routerLink]="[]" fragment="speakerForm" class="form-button">
          Solicite uma proposta
        </a>
      </div>
    </div>
    <div class="bar"></div>
    <div class="profissional-palestras-container">
      <div
        *ngFor="let palestra of (palestrasOferecidas | slice:0:2)"
        class="profissional-palestras-items"
      >
        <div class="profissional-palestras-container-item">
          <p><b>#Palestradestaque</b></p>
          <h2>{{ palestra.nome }}</h2>
          <p>{{ palestra.descricao | slice:0:500 }}{{ palestra.descricao.length > 500 ? '...' : '' }}</p>
        </div>
      </div>
    </div>

  </section>

  <psa-asked-questions *ngIf="profissional.id" [palestrante]="profissional" class="qa"></psa-asked-questions>
  
</main>
<psa-relacionados-carousel [relacionados]="profissionaisRelacionados" *ngIf="!loadingRelacionados"></psa-relacionados-carousel>
