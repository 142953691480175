<footer class="footer-white">
  <div class="content_contaiener row">
    <section class="col">
      <img ngSrc="assets/img/Logo-negativa.svg" width="100" height="46,38" alt="logo-psa" />

      <div class="mt-2 ml-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0E0E0E" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
        </svg>
        <span class="ml-1">Av. Ipiranga, 6681 Sl 601 prédio 99A - Partenon, POA/RS, 90619-900</span>
      </div>

      <div class="ml-2">
        <img src="assets/icons/wpp.svg" width="16" height="16" alt="whatsapp" />
        <span class="ml-1">
          <a target="_blank" href="https://api.whatsapp.com/send?phone=5511942770298&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es">(11) 94277-0298</a>
        </span>
      </div>
    </section>
  </div>
</footer>
