<header>
	<div class="container-header">
	  <div class="left-header">
		<button (click)="redirect('https://profissionaissa.com.br')"><img ngSrc="assets/img/psa-header-logo.svg" height="37" [width]="73" alt="PSA logotipo"></button>
	  </div>
	  <div class="middle-header" *ngIf="!isSmallScreen; else showIcon">
		<div class="middle-item">
		<button (click)="redirect('https://profissionaissa.com.br/palestrantes-e-especialistas/')">Líderes de Pensamento</button>
		</div>
		<div class="middle-item">
		<button (click)="redirect('https://profissionaissa.com.br/blog/')">Conteúdos</button>
		</div>
		<div class="middle-item">
		<button (click)="redirect('https://profissionaissa.com.br/quem-somos/')">Institucional</button>
		</div>
	  </div>
	  <ng-template  #showIcon>
		<div class="middle-icon" (click)="toggleMenu()">
			<img src="assets/icons/widget.svg" alt="3 linhas" width="22" height="22">
		</div>
		<div *ngIf="menuOpen" class="mobile-menu">
		  <button (click)="redirect('https://profissionaissa.com.br/palestrantes-e-especialistas/')">Líderes de Pensamento</button>
		  <button (click)="redirect('https://profissionaissa.com.br/blog/')">Conteúdos</button>
		  <button (click)="redirect('https://profissionaissa.com.br/quem-somos/')">Institucional</button>
		</div>
	  </ng-template>


	  <div class="right-header">
		<button (click)="redirect('https://login.profissionaissa.com.br/')">
			<span class="login">
				<span><img src="assets/icons/lock.svg" alt="Cadeado" width="14" height="14" style="padding-bottom: 0.2rem;"></span>
				<span>Login</span>
			</span>
		</button>
	  </div>
	</div>
</header>