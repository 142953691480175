<div class="whatsapp">
  <div>
    <div class="fixed-icon" (click)="openForm()">
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp Icon">
    </div>
  </div>

  <div class="form-container" [class.show]="!isHidden">
    <button (click)="closeForm()">&times;</button>
    <p>Fale Com um curador agora!</p>
    <div class="_form_433" id="form-active-campaign"></div>
  </div>
</div>
