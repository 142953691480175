import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpeakerService } from 'src/app/services/speaker.service';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { VideoModalComponent } from 'src/app/shared/video-modal/video-modal.component';
import { FormHomeComponent } from '../form-home/form-home.component';
import { SEOService } from 'src/app/services/SEOService';

@Component({
  selector: 'psa-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  searchQuery: string = '';
  searchInput: string = '';
  speakers: any = [];
  loading: boolean = false;
  searched: boolean = false;
  newSearch: boolean = false;
  formFilled: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private palestrantesService: SpeakerService,
    public dialog: MatDialog,
    private meta: Meta,
    private seoService: SEOService,
  ) {
    router.queryParamMap.subscribe(
      (result: any) => {
        this.searchQuery = result.params.search;
      }
    )
  }

  ngOnInit(): void {
    const cookies = document.cookie.split('; ');

    for (let cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'formFilled') {
        this.formFilled = Boolean(value) || false;
      }
    }

    this.loading = true;
    this.seoService.updateCanonical(window.location.href);
    this.meta.updateTag({ name: 'Description', content: "Encontre o palestrante ideal na Profissionais SA | PSA! São +16 mil especialistas em diversos temas para inspirar, engajar e transformar seu evento."});
    this.loading = false;
  }

  openFormModal(): void {
    if(this.formFilled){
      return;
    }
    const dialogRef = this.dialog.open(FormHomeComponent, {
      backdropClass: "search-form-container",
      width: '430px',
      height: '95%'
    });

    dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.searched = false;
        this.formFilled = false;
      }
  });

    dialogRef.backdropClick().subscribe(() => {
      this.searched = false;
      this.formFilled = false;
    });

    dialogRef.componentInstance.formSubmitted.subscribe(() => {
      this.formFilled = true;
      document.cookie = `formFilled=true; path=/; max-age=${60 * 60 * 24}`;
    });
  }

  openVideoModal(): void {
    this.dialog.open(VideoModalComponent, {
      panelClass: "video-modal-container",
      width: '80%',
      data: {
        videoId: 'tdBGvtbmx1A',
        linkVideo: 'https://www.youtube.com/watch?v=tdBGvtbmx1A'
      },
    });
  }

  async searchSpeakers() {
    if(!this.searchInput)
      return;
    this.loading = true;
    this.savePesquisa(this.searchInput);
    this.speakers = await this.palestrantesService.searchSpeakers(this.searchInput);
    this.loading = false;
    this.searched = true;
    this.newSearch = false;
    this.openFormModal();
  }

  savePesquisa(pesquisa: string) {
    this.palestrantesService.savePesquisa(pesquisa).subscribe(
      (result: any) => {
      }
    );
  }
}
