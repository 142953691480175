import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'psa-form-speaker-list',
  templateUrl: './form-speaker-list.component.html',
  styleUrls: [
    'form-speaker-list.component.css',
    '../../../assets/css/bootstrap.css',
  ],
})
export class FormSpeakerListComponent implements OnInit {
  @Output() formSubmitted = new EventEmitter<boolean>();

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    public dialogRef: MatDialogRef<FormSpeakerListComponent>
  ) {}

  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.src =
      'https://psa-profissionaissa.activehosted.com/f/embed.php?id=432';
    script.charset = 'utf-8';

  script.onload = () => {
      setTimeout(() => { // Give time for the form to render
        const submitButton = this._document.getElementById('_form_432_submit');
        if (submitButton) {
          submitButton.addEventListener('click', this.handleSubmit.bind(this));
        }
      }, 1000); 
    };

    this._renderer2.appendChild(this._document.body, script);
  }

  handleSubmit = () => {
    const form = this._document.getElementById('form-active-campaign') as HTMLFormElement;
    if (!form) {
      console.error('Form not found');
      return;
    }
    const requiredFields = form.querySelectorAll('input[required], select[required], textarea[required]');
    let isValid = true;

    requiredFields.forEach((field: Element) => {
      const input = field as HTMLInputElement;
      
      if (!input.value.trim()) {
        isValid = false;
        input.classList.add('error'); // Highlight field with an error
        console.warn(`Field "${input.name}" is required`);
      } else {
        input.classList.remove('error');
      }
    });

    if (!isValid) {
      return;
    }
    this.formSubmitted.emit(true);
    this.dialogRef.close();
  }
}
