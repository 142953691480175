<div class="main__container">
  <div *ngFor="let profissional of speakers">
    <article
      (click)="navigateToRelacionadoProfile(profissional.identifier, profissional.id_tema)"
      class="profissional__relacionados__slide d-flex flex-column justify-content-between align-items-center text-center mb-5"
    >
      <div class="profissional__relacionados__slide__hero">
        <img
          class="profissional__relacionado__image"
          fill
          ngSrc="{{ profissional.image }}"
          alt="{{ 'Palestrante' + profissional.identifier }}"
        />
      </div>
      <sub class="profissional__relacionados__slide__title">
        {{ profissional.nomeCompleto }}
      </sub>
      <p class="profissional__relacionados__slide__text">
        {{ getMinibio(profissional?.title) }}
      </p>
      <button class="custom__button">
        &#123; VER MAIS &#125;
      </button>
    </article>
  </div>
  <div class="not__found" *ngIf="speakers.length == 0">
    <h1>ERRO</h1>
    <img src="../../../assets/img/404.svg" alt="Usuários não encontrados">
    <div class="not__found__text">
      <p>Palestrante(s) não encontrado(s) no nosso banco, por favor insira uma nova pesquisa </p>
    </div>
  </div>
</div>
